.title {
  margin-top: 10;
  font-size: 16;
  color: white;
  text-align: center;
}
.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(45, 68, 213, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
