.Pagination {
    width: 100%;
    font-size: 16px;
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    z-index: 2;
}