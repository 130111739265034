.FinalPage {
    display: flex;
    justify-content: center;
    height: calc(100% - 40px);
    margin: 10px;
    border-radius: 10px;
    background: #FFF;
}

.FinalPage .FinalPage-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
}

.FinalPage .FinalPage-container .FinalPage-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow-y: auto;
    padding: 0px 20px 20px 20px;
}

.FinalPage .FinalPage-container .FinalPage-title {
    font-family: "Disket-Mono-Regular";
    color: rgba(0, 0, 0, 1);
    font-size: var(--fs6);
    text-align: center;
    text-transform: uppercase;
    padding: 10px 20px 0px 20px;
    letter-spacing: -2;
}

.FinalPage .FinalPage-container .FinalPage-total {
    display: flex;
    flex-direction: column;
}

.FinalPage .FinalPage-container .FinalPage-total .FinalPage-total-text {
    font-family: 'Disket-Mono-Regular';
    font-size: 20px;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3;
}

.FinalPage .FinalPage-container .FinalPage-total .FinalPage-total-upload {
    font-family: 'Disket-Mono-Regular';
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    text-transform: uppercase;
    opacity: 0.6;
}

.FinalPage .FinalPage-container .FinalPage-icon {
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.FinalPage .FinalPage-container .FinalPage-description {
    font-family: "Manrope";
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    text-align: center;
    max-width: 500px;
}

.FinalPage .FinalPage-container .FinalPage-tickets {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 90px;
    overflow-y: auto;
}

.FinalPage .FinalPage-container .FinalPage-tickets .TicketResult {
    flex: 1
}

.FinalPage .FinalPage-container .FinalPage-buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.mobile .FinalPage .FinalPage-container .FinalPage-buttons {
    flex: 1;
    align-content: flex-end;
    align-items: flex-end;
}

.FinalPage .FinalPage-container .FinalPage-buttons button {
    min-width: 185px;
}

@media screen and (max-width: 430px) {
    .FinalPage {
        height: calc(100% - 20px);
    }

    .FinalPage .FinalPage-container .FinalPage-title {
        font-size: var(--fs4);
    }

    .FinalPage .FinalPage-container .FinalPage-buttons button {
        min-width: 100%;
        padding: 0px 5px;
        white-space: normal;
        word-break: break-word;
    }
}