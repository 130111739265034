.TicketsList {
  height: 100%;
  overflow: hidden;
}

.TicketsList .TicketsList-header {
  display: flex;
  align-items: center;
  color: var(--white);
  font-family: "Disket-Mono-Regular";
  font-size: var(--fs5);
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -2px;
  padding: 20px 20px 0 20px;
}

.TicketsList .TicketsList-content {
  height: calc(100% - 50px);
  overflow-y: auto;
}

.TicketsList .TicketsList-content .TicketsList-container {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(auto, 360px));
  grid-template-rows: auto;
  grid-auto-flow: dense;
  justify-content: center;
  padding: 20px;
}

.mobile .TicketsList .TicketsList-header {
  padding: 0px;
}

.mobile .TicketsList .TicketsList-content {
  padding: 0px 5px;
}

.mobile .TicketsList .TicketsList-content .TicketsList-container {
  padding: 0px 0px 40px 0px;
}

.TicketItem-share .ant-modal-confirm-paragraph {
  max-width: 100%;
}
