.Checkbox {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.Checkbox .Checkbox-border {
  border: 1px solid var(--black);
  border-radius: 5px;
  background: transparent;
  width: 28px;
  min-width: 28px;
  height: 28px;
  min-height: 28px;
  padding: 0;
}

.Checkbox .Checkbox-mark {
  background-color: var(--black);
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin: 3px;
}

.Checkbox .Checkbox-label {
  margin-left: 10px;
  color: var(--black);
  font-family: 'Manrope';
  font-weight: 600;
  line-height: 18px;
}