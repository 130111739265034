.PNRList {
}

.PNRList .PNRList-container {
  max-width: 1150px;
  margin: 0 auto;
}

.mobile .PNRList .PNRList-container {
  padding-bottom: 135px;
}

.PNRList .PNRList-container .PNRList-pnrs-container {
  margin-bottom: 10px;
}

.PNRList .PNRList-container .PNRList-pnrs-container .PNRList-pnrs-date {
  margin-bottom: 10px;
  color: var(--black);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}

.PNRList .PNRList-container .PNRList-pnrs {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(auto, 358px));
  grid-template-rows: auto;
  grid-auto-flow: dense;
  justify-content: center;
}

.mobile .PNRList .PNRList-container .PNRList-pnrs {
  padding: 0 5px;
}
