.PassengerInfo {
    margin-bottom: 10px;
}

.PassengerInfo .PassengerInfo-row {
    display: flex;
    justify-content: space-between;
}

.PassengerInfo .PassengerInfo-column {
    display: flex;
    flex-direction: column;
}

.PassengerInfo .PassengerInfo-column.left {
    align-items: flex-start;
}

.PassengerInfo .PassengerInfo-column.right {
    align-items: flex-end;
}

.PassengerInfo .PassengerInfo-title {
    color: var(--black);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.32;
}

.PassengerInfo .PassengerInfo-value {
    color: var(--black);
    font-size: var(--fs1);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.safari .PassengerInfo .PassengerInfo-value {
    color: var(--black);
    font-size: var(--fs1);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}