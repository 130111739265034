.PDFViewer {
    max-height: 100%;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.PDFViewer .PDFViewer-pdf {
    box-shadow: 0 0 10px var(--background);
    border-radius: 4px;
    height: calc(100% - 50px);
    max-height: calc(100% - 50px);
    max-width: 100%;
    overflow: hidden;
    cursor: pointer;
}

.PDFViewer .PDFViewer-pdf canvas {
    margin: 0 auto;
}

.PDFViewer .PDFViewer-pages {
    font-size: 16px;
    background: var(--white);
    box-shadow: 0 0 10px var(--background);
    border-radius: 4px;
    margin: 5px 0;
    display: flex;
    align-items: center;
}

.PDFViewer .PDFViewer-pages span {
    padding: 0 3px;
}