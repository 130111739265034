.Support-container {
 overflow: auto !important; 
 height: 100% !important;
}
.Support {
  margin: 20px;
}
.Support-header {
  background-color: transparent;
}

.mobile .Support {
  padding-bottom: 80px;
}
