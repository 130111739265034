.Loader {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--black);
    opacity: 0.80;
    width: 100%;
    height: 100%;
    z-index: 10001;
    left:0;
    top:0;
}

.Loader span {
    color: var(--white);
    font-size: 16px;
    margin-bottom: 15px;
    text-align: center;
}

.mobile .Loader {
    width: 100vw;
    height: 100vh;
}