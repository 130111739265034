.Sidebar {
}

.Sidebar .Sidebar-logo {
  width: 100%;
  max-width: 200px;
  max-height: 64px;
}

.Sidebar .Sidebar-logo.collapsed {
  padding: 0 15px;
}
.ant-menu-dark .ant-menu-item-selected {
  color: var(--black);
}
.Sidebar .ant-layout-sider-trigger {
  background: #222;
}
