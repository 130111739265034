.Profile {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Profile .Profile-header {
  display: flex;
  align-items: center;
  color: '#444';
  font-family: "Disket-Mono-Regular";
  font-size: var(--fs5);
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -2px;
  padding: 20px 20px 0 20px;
}

.Profile .Profile-container {
  margin: 20px;
  border-radius: 10px;
  overflow: hidden
}

.mobile .Profile .Profile-header {
  padding: 0px;
}

.mobile .Profile .Profile-container {
  margin: 0px 20px 20px 20px;
}