.Gallery {}

.Gallery .ant-modal {
    display: inline-flex !important;
    align-items: center !important;
    width: 100% !important;
    height: 100% !important;
}

.Gallery .ant-modal .ant-modal-content {
    background: none;
    box-shadow: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Gallery .ant-modal .ant-modal-content .ant-modal-close {
    top: 15px;
    right: 15px;
    border-radius: 50%;
    background-color: red;
    width: 30px;
    height: 30px;
    color: var(--white);
    border: 1px solid var(--white);
}

.Gallery .Gallery-carousel-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.Gallery div[tabindex] {
    outline: none;
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 80vh;
}