.StorePopover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #0006;
  z-index: 10002;
}

.StorePopover .StorePopover-content {
  background: var(--white);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 6px 6px 0 0;
}

.StorePopover .StorePopover-header {
  text-align: center;
  font-weight: 500;
  font-size: var(--fs4);
  border-bottom: 1px solid var(--background);
  padding: 5px 10px 5px 10px;
}

.StorePopover .StorePopover-list {
  padding: 5px 10px 5px 10px;
}

.StorePopover .StorePopover-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: var(--fs3);
  padding: 5px 0;
}

.StorePopover .StorePopover-iconContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.StorePopover .StorePopover-icon {
  width: 40px;
  max-width: 40px;
  height: 40px;
  max-height: 40px;
  margin-right: 10px;
}

.StorePopover button {
  width: 150px;
}

@media screen and (max-width: 430px) {
  .StorePopover .StorePopover-header {
    font-size: var(--fs2);
  }

  .StorePopover .StorePopover-row {
    font-size: var(--fs1);
  }
}
