.PnrAccordion {
    width: 100%;
}

.PnrAccordion .PnrAccordion-header {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    cursor: pointer;
    user-select: none;
    padding: 0 10px;
}

.PnrAccordion .PnrAccordion-header .PnrAccordion-header-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    color: var(--black);
}

.PnrAccordion .PnrAccordion-content {
    padding: 15px 10px;
}

.PnrAccordion .PnrAccordion-content .PnrAccordion-container.visible {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(auto,358px));
    grid-template-rows: auto;
    grid-auto-flow: dense;
    justify-content: center;
}

.PnrAccordion .PnrAccordion-content .PnrAccordion-container .PnrAccordion-compact {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.PnrAccordion .PnrAccordion-content .PnrAccordion-container .PnrAccordion-compact .PnrAccordion-first-subcontainer {
    border-radius: 20px;
    border: 1px solid var(--background);
    background: rgba(255, 255, 255, 0.64);
    backdrop-filter: blur(12px);
    height: 50px;
    width: calc(100% - 15px);
    position: absolute;
    bottom: -5px;
    z-index: 2;
}

.PnrAccordion .PnrAccordion-content .PnrAccordion-container .PnrAccordion-compact .PnrAccordion-second-subcontainer {
    border-radius: 20px;
    border: 1px solid var(--background);
    background: rgba(255, 255, 255, 0.24);
    backdrop-filter: blur(12px);
    height: 50px;
    width: calc(100% - 30px);
    position: absolute;
    bottom: -10px;
}

.mobile .PnrAccordion .PnrAccordion-content .PnrAccordion-container {
    padding: 0px 5px;
}

.mobile .PnrAccordion .PnrAccordion-header {
    padding: 0 20px;
}

.mobile .PnrAccordion .PnrAccordion-content {
    padding: 15px 0px;
}

@media screen and (max-width: 430px) {
    
    .mobile .PnrAccordion .PnrAccordion-content .PnrAccordion-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mobile .PnrAccordion .PnrAccordion-content .PnrAccordion-container .PnrAccordion-compact {
        max-width: 358px;
        width: 100%;
    }
}