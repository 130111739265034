.document {
  height: 15vh;
  width: 20vh;
  overflow: auto;
  border: 1px solid #ddd;
  align-items: center;
  align-self: center;
  align-content: center;
  text-align: center;
}

.file-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  text-align: center;
  margin-left: 7%;
  overflow: auto;
}

.file-image {
  display: flex;
  flex-direction: row;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-left: 10px;
  padding: 10px;
}

.file-pdf {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
}

.Validator-header {
  font-family: "Disket-Mono-Regular";
  font-weight: 400;
  font-size: 32px;
  color: var(--black);
  text-align: center;
  line-height: 36px;
  letter-spacing: -2px;
}

.Validator-footer {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #ccc;
}

.mobile .Validator-footer {
  padding: 15px 50px;
}
.SelectType-btn {
  transition: border-color 0.3s ease-in-out;
}

.SelectType-btn:hover {
  border-color: #444;
}

@media screen and (max-width: 430px) {
  .Validator-header {
    font-size: 24px;
    line-height: 26px;
  }
}

.field .dateInput::-webkit-datetime-edit-day-field:focus,
.field .dateInput::-webkit-datetime-edit-month-field:focus,
.field .dateInput::-webkit-datetime-edit-year-field:focus {
  background-color: #444;
  color: white;
  outline: none;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #444;
  border-color: #444;
}
