.TicketItem {
  display: flex;
  flex-flow: column nowrap;
  max-width: 393px;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background: var(--white);
}

.TicketItem.sync {
  border-bottom: 5px solid #444;
}

.TicketItem .TicketItem-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.TicketItem .TicketItem-content {
  width: 100%;
}

.TicketItem .TicketItem-content .TicketItem-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.TicketItem .TicketItem-content .TicketItem-row .TicketItem-airline,
.TicketItem .TicketItem-content .TicketItem-row .TicketItem-flight {
  display: flex;
  align-items: center;
}

.TicketItem .TicketItem-content .TicketItem-row .TicketItem-airline img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.TicketItem .TicketItem-content .TicketItem-row .TicketItem-flight img {
  margin-left: 5px;
}

.TicketItem .TicketItem-content .TicketItem-text {
  font-weight: 600;
}
.safari .TicketItem .TicketItem-content .TicketItem-text {
  font-weight: 550;
}

.TicketItem .TicketItem-content .TicketItem-comleted {
  display: flex;
  align-items: center;
  justify-content: center;
  color: green;
  margin-bottom: 2%;
}

.TicketItem .TicketItem-content .TicketItem-comleted img {
  margin-right: 5px;
}

.TicketItem .TicketItem-content .TicketItem-buttons {
  display: flex;
  flex-direction: column;
}

.TicketItem .TicketItem-buttons .TicketItem-share-btn {
  display: flex;
  align-items: center;
}

.TicketItem .TicketItem-buttons .TicketItem-shared {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 1px solid #fa9c14;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  color: #fa9c14;
}

.TicketItem .TicketItem-buttons .TicketItem-shared img {
  margin-inline-end: 8px;
}
