.TicketFooter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 5px;
}

.TicketFooter .TicketFooter-Buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
}

.TicketFooter .TicketFooter-Buttons .TicketFooter-Wallet {
  background: var(--black);
  border-radius: 6px;
  padding: 0px 16px;
  box-shadow: none;
  border: none;
}

.TicketFooter
  .TicketFooter-Buttons
  .TicketFooter-Wallet
  .TicketFooter-Wallet-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.TicketFooter
  .TicketFooter-Buttons
  .TicketFooter-Wallet
  .TicketFooter-Wallet-container
  .TicketFooter-Wallet-text {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  align-items: flex-start;
  line-height: 14px;
}

.TicketFooter .TicketFooter-Buttons .TicketFooter-Wallet span {
  color: var(--white);
}

.TicketFooter
  .TicketFooter-Buttons
  .TicketFooter-Wallet
  .TicketFooter-Wallet-container
  .TicketFooter-Wallet-text
  span:first-child {
  font-size: 12px;
}

.TicketFooter .TicketFooter-Buttons .TicketFooter-Wallet img {
  width: 35px;
}
