.DocumentViewer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
}

.DocumentViewer-container .DocumentViewer-row {
  justify-content: space-between;
  max-width: 450px;
  width: 100%;
  display: flex;
  margin: 4px 0;
}

.DocumentViewer-container .DocumentViewer-row .DocumentViewer-label {
  font-size: 16px;
  font-family: "Manrope";
  opacity: 0.48;
  color: var(--black);
}

.DocumentViewer-container .DocumentViewer-row .DocumentViewer-value {
  font-family: "Manrope";
  font-weight: 600;
  font-size: 16px;
  line-height: 21.86px;
  text-align: right;
  color:  var(--black);
}

.container {
  /* No styles defined */
}

.row {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: middle;
}

.label {
  max-width: 50%;
  font-family: "Manrope";
  font-weight: 400;
  font-size: 14px;
  line-height: 19.12px;
  color:  var(--black);
}

.value {
  max-width: 50%;
  font-family: "Manrope";
  font-weight: 600;
  font-size: 16px;
  line-height: 21.86px;
  text-align: right;
  color:  var(--black);
}