.FileViewer {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: center;
    position: relative;
    border: 1px solid #e3e0e0;
    border-radius: 10px;
    padding: 5px;
    background-color: #f2f2f2;
}

.FileViewer.small, .FileViewer.small > img {
    width: 150px;
    height: 150px;
}

.FileViewer.medium, .FileViewer.medium > img {
    width: 40vh;
    height: 35vh;
    max-width: 100%;
    max-height:100%;
}

.FileViewer.large, .FileViewer.large > img {
    max-width: 500px;
    max-height: 500px;
    width: 100%;
    height: 100%;
}

.FileViewer .FileViewer-remove {
    position: absolute;
    left: 5px;
    top: 5px;
    z-index: 3;
    min-width: 30px !important;
    width: 30px !important;
    height: 30px !important;
    padding: 0 !important;
}

.FileViewer .FileViewer-preview:hover {
    opacity: 1;
}

.FileViewer .FileViewer-preview {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    background: rgba(0,0,0,.5);
    cursor: pointer;
    opacity: 0;
    border-radius: 10px;
    z-index: 2;
}

.FileViewer img {
    padding: 5px;
    border-radius: 10px;
    object-fit: cover;
    cursor: pointer;
}