.option {
  background-color: #0000000a;
  padding: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.selected {
  background-color: #444;
}
