.FlightDetails {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}

.FlightDetails .FlightDetails-header {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  opacity: 0.48;
}

.FlightDetails .FlightDetails-content {
  display: flex;
  justify-content: space-between;
}

.FlightDetails .FlightDetails-content img {
  width: 100%;
  max-width: 190px;
  min-width: 100px;
}

.safari .FlightDetails .FlightDetails-content .FlightDetails-aircode {
  color: var(--black);
  font-family: "Manrope";
  font-size: var(--fs4);
  font-style: normal;
  font-weight: 550;
  line-height: normal;
  text-transform: uppercase;
}

.TicketInfo .FlightDetails .FlightDetails-content .FlightDetails-aircode {
  color: var(--black);
  font-family: "Manrope";
  font-size: var(--fs4);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.FlightDetails .FlightDetails-footer {
  display: flex;
  justify-content: space-between;
}

.FlightDetails .FlightDetails-footer .FlightDetails-time {
  color: var(--black);
  font-family: "Manrope";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
