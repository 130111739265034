.FileUploader {}

.FileUploader .FileUploader-dnd {
    display: block;
    max-width: 700px;
    margin: 0 auto;
}

.FileUploader .FileUploader-dnd .ant-upload-list-item-container {
    display: none !important;
  }

.FileUploader .FileUploader-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.FileUploader .FileUploader-icon img {
    width: 15vh;
    height: 15vh;
    max-width: var(--default-icon-size);
    max-height: var(--default-icon-size);
    margin: 0 5px;
    pointer-events: none;
    -moz-user-select: none; 
    -webkit-user-select: none; 
    -ms-user-select: none; 
    user-select: none;
}

.FileUploader .FileUploader-description {
    color: #9c9999;
}

.FileUploader .FileUploader-preview {
    padding: 15px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    max-height: 345px;
}