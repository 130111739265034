.validationContainerStyle {
  /* margin-top: 10px;
  margin-bottom: 10px; */
}
.validationTitleStyle {
  color: black;
  font-family: "Manrope";
  align-self: center;
  text-align: center;
  font-size: 16px;
}
.validationCodeStyle {
  font-family: "Disket-Mono-Regular";
  color: rgba(0, 0, 0, 1);
  font-size: 32px;
  text-align: center;
  text-transform: uppercase;
}
.ticketsTitleStyle {
  text-align: center;
  font-weight: 300;
  font-family: "Manrope";
  font-size: 13px;
  color: black;
}

.ticketsCodeStyle {
  font-size: 18px;
  text-align: center;
  color: black;
  text-transform: uppercase;
  letter-spacing: 10px;
  font-family: "Manrope";
  font-weight: 700;
}

@media screen and (max-width: 430px), screen and (max-height: 430px) {
  .validationCodeStyle {
    font-size: 24px;
  }
}
