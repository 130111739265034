.UserForm {
  max-width: 1150px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow-y: auto;
  padding: 20px;
}

.userPicture {
  display: flex;
  justify-content: center;
}

.avatar {
  width: 800px !important;
  /* or any size you want */
  height: 280px !important;
  /* or any size you want */
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-top: 5%;
}
.avatar:hover {
  cursor: pointer;
}

.uploadButton {
  justify-content: center;
  align-items: center;
  display: flex;
}

.noAvatar {
  width: 300px !important;
  /* or any size you want */
  height: 280px !important;
  /* or any size you want */
  border: 2px solid #ddd;
  border-radius: 10px;
  margin-top: 5%;
  justify-content: center;
  align-items: center;
  border-style: dashed;
  display: flex;
}

.inputContainer {
  /* width: 100% !important; */
  border-radius: 16px;
  min-height: 48px;
  appearance: none;
  -webkit-appearance: none;
}

.tagsContainer {
  width: 100%;
  background-color: var(--white);
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 16px;
  border: 1px solid var(--white);
  cursor: pointer;
}

.tagsContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
}

.tagsContent h5 {
  margin: 0px;
  font-size: 14px;
}

.tags {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tag {
  padding: 3px;
  border-radius: 14px;
  height: 2.5rem;
  position: absolute;
  border: 1px solid var(--white);
  margin-right: 7%;
}
.safari .otherTitle {
  font-weight: 599;
}
.otherTitle {
  font-weight: bold;
}
.safari .tagsContainer .tagsTitle {
  font-weight: 599;
}
.tagsContainer .tagsTitle {
  font-weight: bold;
}
.tagsContainer .tagName {
  font-weight: bold;
}
.safari .tagsContainer .tagName {
  font-weight: 599;
}
.safari .tagsContainer .plusElem {
  color: var(--black);
  font-weight: 599;
  z-index: 999;
  margin: 0px;
}
.plusElem {
  color: var(--black);
  font-weight: 700;
  z-index: 999;
  margin: 0px;
}

.buttonsContainer {
  width: 300px;
}
.userInfoContainer .inputContainer::-webkit-datetime-edit-day-field:focus,
.userInfoContainer .inputContainer::-webkit-datetime-edit-month-field:focus,
.userInfoContainer .inputContainer::-webkit-datetime-edit-year-field:focus {
    background-color: #444;
    color: white;
    outline: none;
}
@media only screen and (max-width: 1130px) {
  .tag {
    margin-right: 10%;
  }
}

@media only screen and (max-width: 1030px) {
  .buttonsContainer {
  }

  .tag {
    margin-right: 12%;
  }
}

@media only screen and (max-width: 767px) {
  .userPicture {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
  }

  .buttonsContainer {
  }

  .userInfoContainer {
    margin-top: 5%;
  }

  .otherContainer {
  }

  .tagsContent {
    position: relative;
    cursor: pointer;
  }

  .avatarContainer {
  }
}

@media only screen and (max-width: 600px) {
  .avatarContainer {
    /* margin-left: 0%;
    width: 100%; */
  }
}

@media only screen and (max-width: 512px) {
  .userPicture {
    flex-direction: column;
  }

  .buttonsContainer {
  }
}

@media only screen and (max-width: 412px) {
  .tag {
    margin-right: 15%;
  }
}

/* For desktop */
@media (min-width: 1024px) {
  .avatar {
    width: 300px !important;
    /* or any size you want */
    height: 300px !important;
    /* or any size you want */
  }
}

/* For tablet */
@media (min-width: 768px) and (max-width: 1023px) {
  .avatar {
    width: 200px !important;
    /* or any size you want */
    height: 200px !important;
    /* or any size you want */
  }

  .noAvatar {
    width: 200px !important;
    height: 200px !important;
  }
}

/* For mobile */
@media (max-width: 767px) {
  .avatar {
    width: 200px !important;
    /* or any size you want */
    height: 200px !important;
    /* or any size you want */
  }

  .noAvatar {
    width: 200px !important;
    /* or any size you want */
    height: 200px !important;
    /* or any size you want */
  }
}
