.Tabs {
}

.Tabs.ant-tabs {
  align-items: center;
  height: 100%;
}

.Tabs.ant-tabs .ant-tabs-nav::before {
  border-bottom: none;
}

.Tabs.ant-tabs .ant-tabs-nav .ant-tabs-nav-list {
  width: 100%;
}

.Tabs.ant-tabs .ant-tabs-nav .ant-tabs-tab {
  height: 38px;
  border-radius: 18px;
  margin: 0;
  width: 100%;
}

.Tabs.ant-tabs .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  background: var(--white);
}

.Tabs.ant-tabs .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn {
  color: var(--white);
  margin: 0 auto;
  font-weight: 400;
  text-shadow: none;
}

.Tabs.ant-tabs
  .ant-tabs-nav
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: var(--black);
}

.Tabs.ant-tabs .ant-tabs-content-holder {
  color: #fff;
  height: 100%;
  width: 100%;
  margin-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}
.Tabs.ant-tabs
  .ant-tabs-nav
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  border: none !important;
}
.Tabs.ant-tabs .ant-tabs-content-holder .ant-tabs-content {
  /* max-width: 1150px; */
  height: 100%;
  /* margin: 0 auto; */
}

.Tabs.ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane {
  height: 100%;
}

.mobile .Tabs.ant-tabs .ant-tabs-content-holder {
  margin-top: 10px;
  padding: 0px;
}

.mobile
  .Tabs.ant-tabs
  .ant-tabs-content-holder
  .ant-tabs-content
  .ant-tabs-tabpane {
  /* padding-bottom: 50px; */
}
