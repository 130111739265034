.PNRItem {
  max-width: 358px;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  padding: 20px;
  color: var(--black);
  border-radius: 20px;
  /* border: 1px solid #3651fe; */
  background: var(--white);
  cursor: pointer;
}

.PNRItem.compact {
  width: 100%;
  max-width: none;
  height: auto;
  z-index: 10;
  position: relative;
}

.PNRItem.compact.empty-row-first {
  position: absolute;
  width: 99%;
  bottom: 10px;
  left: 5px;
  z-index: 3;
}

.PNRItem.compact.empty-row-second {
  position: absolute;
  width: 98%;
  bottom: 5px;
  left: 10px;
  z-index: 2;
}

.PNRItem .PNRItem-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 12px;
  min-height: 24px;
}

.safari .PNRItem .PNRItem-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 599;
  font-size: 12px;
  min-height: 24px;
}

.PNRItem .PNRItem-header .PNRItem-flight-number {
  display: flex;
  align-items: center;
  line-height: 15px;
}

.PNRItem .PNRItem-header .PNRItem-flight-number img {
  margin-left: 5px;
}

.PNRItem .PNRItem-block {
  display: flex;
  max-width: 318px;
  width: 100%;
  flex-direction: column;
  gap: 5px;
}

.PNRItem.compact .PNRItem-block {
  width: 100%;
  max-width: none;
}

.PNRItem .PNRItem-block .PNRItem-content-header {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  opacity: 0.48;
}

.PNRItem .PNRItem-block .PNRItem-content {
  display: flex;
  justify-content: space-between;
}

.PNRItem.compact .PNRItem-block .PNRItem-content img {
  width: 100%;
  max-width: 350px;
}

.PNRItem .PNRItem-block .PNRItem-content img {
  width: 100%;
  max-width: 190px;
  min-width: 100px;
}

.PNRItem .PNRItem-block .PNRItem-content .PNRItem-aircode {
  color: var(--black);
  font-family: "Manrope";
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.safari .PNRItem .PNRItem-block .PNRItem-content .PNRItem-aircode {
  color: var(--black);
  font-family: "Manrope";
  font-size: 23px;
  font-style: normal;
  font-weight: 599;
  line-height: normal;
  text-transform: uppercase;
}

.PNRItem .PNRItem-block .PNRItem-content-footer {
  display: flex;
  justify-content: space-between;
}

.PNRItem .PNRItem-block .PNRItem-content-footer .PNRItem-time {
  color: var(--black);
  font-family: "Manrope";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.PNRItem .PNRItem-separator {
  margin: 17px 0;
  border-bottom: 4px var(--background) dotted;
  position: relative;
}

.PNRItem .PNRItem-separator .PNRItem-bigdot-left {
  position: absolute;
  height: 35px;
  width: 35px;
  background-color: var(--background);
  left: -40px;
  top: -15px;
  border-radius: 50px;
}

.PNRItem .PNRItem-separator .PNRItem-bigdot-right {
  position: absolute;
  height: 35px;
  width: 35px;
  background-color: var(--background);
  right: -40px;
  top: -15px;
  border-radius: 50px;
}

.PNRItem .PNRItem-footer {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.PNRItem .PNRItem-footer .PNRItem-bag {
  display: flex;
  align-items: center;
  line-height: 14px;
}

.PNRItem .PNRItem-footer .PNRItem-bag .PNRItem-bag-icon {
  margin-right: 5px;
}

.PNRItem .PNRItem-footer .PNRItem-passenger {
  color: var(--black);
  font-family: "Manrope";
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.safari .PNRItem .PNRItem-footer .PNRItem-passenger {
  color: var(--black);
  font-family: "Manrope";
  font-size: 23px;
  font-style: normal;
  font-weight: 599;
  line-height: normal;
  text-transform: uppercase;
}
.PNRItem .PNRItem-footer .PNRItem-button {
  background-color: #444;
  border-radius: 16px;
  border: none;
  padding: 8px 12px;
  color: var(--white);
  cursor: pointer;
}

.PNRItem .PNRItem-footer .PNRItem-button.disabled {
  opacity: 0;
  z-index: -1;
}
