.PreparationScreen {
    
}

.PreparationScreen .PreparationScreen-header {
    display: flex;
    align-items: center;
    color: '#444';
    font-family: "Disket-Mono-Regular";
    font-size: var(--fs5);
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: -2px;
    padding: 20px 20px 0 20px;
}

.PreparationScreen .PreparationScreen-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    margin-top: 15px;
}

.PreparationScreen .PreparationScreen-content .PreparationScreen-buttons {
    margin-top: 10px;
    display: flex;
    gap: 5px;
    max-width: 393px;
    width: 100%;
}

@media screen and (max-width: 400px) {
    .PreparationScreen .PreparationScreen-header {
      font-size: var(--fs4);
    }
  }