.Header {
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

.Header .Header-button {
  cursor: pointer;
}

.Header .Header-button:hover {
  border: 1px solid #444 !important;
}

.Header .Header-button:active {
  opacity: 0.5;
}

.mobile .Header .Header-title {
  font-size: 32px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
} 

.mobile .Header .Header-title .Header-logo {
  max-width: 80px;
  margin-right: 15px;
}

.user-avatar {
  border: none !important;
  background-color: transparent !important;
}
