.ShareInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ShareInfo .ShareInfo-text {
    font-weight: 500;
    margin: 10px 0
}

.ShareInfo .ant-qrcode {
    max-width: 264px;
    max-height: 264px;
    width: 100% !important;
    height: 100% !important;
}