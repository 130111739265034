.TicketResult {
    display: flex;
    align-items: center;
    justify-content: center;
}

.TicketResult .TicketResult-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TicketResult .TicketResult-icon {
    margin-left: 5px;
    display: flex;
}

.TicketResult .TicketResult-content .TicketResult-number {
    font-family: "Disket-Mono-Regular";
    text-align: center;
    font-size: 26px;
    font-weight: bold;
}

.TicketResult .TicketResult-content .TicketResult-description {
    font-size: 16px;
    text-align: center;
}