.AnimatedPreloader {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.AnimatedPreloader .AnimatedPreloader-progress .AnimatedPreloader-animation-container {
    position: relative;
    left: 25px;
}

.AnimatedPreloader .AnimatedPreloader-progress .AnimatedPreloader-animation-container .AnimatedPreloader-animation {
    width: 150px;
    height: 150px;
}

@media screen and (max-width: 430px), screen and (max-height: 430px) {
    .AnimatedPreloader {
        margin: 10px 0;
    }
    
    .AnimatedPreloader .AnimatedPreloader-progress .ant-progress-inner {
        width: 150px !important;
        height: 150px !important;
    }

    .AnimatedPreloader .AnimatedPreloader-progress .AnimatedPreloader-animation-container {
        left: 15px;
    }

    .AnimatedPreloader .AnimatedPreloader-progress .AnimatedPreloader-animation-container .AnimatedPreloader-animation {
        width: 120px;
        height: 120px;
    }
}