.Button {}


.Button.default {
  color: var(--primary);
}

.Button.Button.default:disabled {
  background-color: var(--white);
  border-color: var(--grey);
  opacity: 0.47;
}