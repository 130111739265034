.infoHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NotificationCard-airName {
  opacity: 0.5;
  color: var(--black);
  font-weight: 400;
}

.NotificationCard-airCode {
  color: var(--black);
  font-size: 23px;
  font-weight: 700;
}
.safari .NotificationCard-airCode {
  font-weight: 599;
}

p:hover,
p:focus {
  cursor: pointer;
  color: blue;
  /* Replace with your desired hover/focus color */
}

.NotificationCard-deleteText:hover,
.NotificationCard-deleteText:focus {
  cursor: pointer;
  color: red;
}

.NotificationCard-logo-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.NotificationCard-airline-logo {
  max-width: 270px;
  width: 100%;
  padding: 0 10px;
}

@media screen and (max-width: 615px) {
  .NotificationCard-airName {
    font-size: 12px;
  }

  .NotificationCard-airCode {
    color:  var(--black);
    font-size: 20px;
    font-weight: 700;
  }

  .infoHeaderContainer {
  }
}
