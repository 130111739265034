.Notifications {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
}
.NotificationList-container {}

.Notifications .ant-tabs-content-holder {
  overflow-y: hidden !important;
}

.Notifications .Notifications-header {
  display: flex;
  align-items: center;
  color: var(--white);
  font-family: "Disket-Mono-Regular";
  font-size: var(--fs5);
  word-break: break-all;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -2px;
  padding: 20px 20px 0 20px;
}

.notificationCardContainer {
  max-width: 680px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 15px;
}

.Notifications .Notifications-content {
  height: calc(100% - 50px);
  overflow-y: auto;
}

.mobile .Notifications .Notifications-header,
.mobile .Notifications .Notifications-content .Notifications-container {
  padding: 0px;
}