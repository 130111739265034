:root {
  --white: #FFF;
  --background: #eee;
  --background-light: #3651feb3;
  --grey: #d9d9d9;
  --black: #000;
  --background-dark:#444

  --fs0: 14px;
  --fs1: 16px;
  --fs2: 18px;
  --fs3: 20px;
  --fs4: 24px;
  --fs5: 32px;
  --fs6: 36px;
  --fs7: 38px;
}