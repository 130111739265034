html,
body,
#root {
  width: 100vw;
  margin: 0;
  overflow: hidden;
  font-size: 13px;
  font-family: Manrope;
  border: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: --var(background);
}

#form .form-group {
  max-width: 550px;
  /* overflow-y: auto; */
  margin-left: auto;
  margin-right: auto;
}

#form .error-detail {
  color: red;
  font-weight: 500;
  margin: 0px;
  list-style: none;
  padding: 0;
}

#root fieldset {
  width: 100%;
}

.mobile .ant-dropdown .ant-dropdown-menu {
  padding: 8px;
}

body input[type=date] {
  min-height: 48px;
  line-height: normal;
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
}

.mobile.isIos input[type=date]::before {
  content: attr(placeholder);
  color: #CCC;
  font-size: 14px;
}

.mobile.isIos input[type=date]:focus[value]:not([value=""]):before {
  display: none;
  content: "" !important;
}

.mobile.isIos input[type=date][value]:not([value=""]):before {
  display: none;
  content: "" !important;
}

.mobile .ant-result {
  padding: 0px 16px 16px;
}

.mobile .ant-result .ant-result-title {
  font-size: 20px;
}