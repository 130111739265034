.DocumentGallery {
 
  width: 100%;
  padding: 15px;
  overflow-y: auto;

  display: flex;

  flex-wrap: nowrap;
  white-space: nowrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 16px;
}

.DocumentGallery .FileViewer:first-child {
  /* margin-left: auto; */
}

.DocumentGallery .FileViewer:last-child {
  /* margin-right: auto; */
}

.imageViewer {
  width: 100% !important;
  height: 150px !important;
  object-fit: contain;
}
