.TicketNumber {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.08);
  padding: 12px 8px;
  margin-bottom: 5px;
}

.TicketNumber .TicketNumber-number {
  opacity: 0.9;
  color: var(--black);
  font-family: "Manrope";
  font-size: var(--fs2);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 10px;
}

.safari .TicketNumber .TicketNumber-number {
  opacity: 0.9;
  color: var(--black);
  font-family: "Manrope";
  font-size: var(--fs2);
  font-style: normal;
  font-weight: 590;
  line-height: normal;
  letter-spacing: 10px;
}
