.MobileSidebar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 358px;
    max-height: 148px;
    min-height: 88px;
    padding: 16px;
    position: fixed;
    bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    border-radius: 32px;
    background: rgba(0, 0, 0, 0.80);
    backdrop-filter: blur(32px);
    z-index: 15;
}

.MobileSidebar .MobileSidebar-menu {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.MobileSidebar .MobileSidebar-menu {
    background: none;
    width: 100%;
}

.MobileSidebar .MobileSidebar-menu::before {
    display: none;
}

.MobileSidebar .MobileSidebar-menu .ant-menu-item {
    color: var(--white);
    display: flex;
    align-items: center;
    border-radius: 18px;
}

.MobileSidebar .MobileSidebar-menu .ant-menu-item:focus-visible {
    outline: 0;
    outline-offset: 0;
    transition: none;
}

.MobileSidebar .MobileSidebar-menu .ant-menu-item::after {
    border: none;
}

.MobileSidebar .MobileSidebar-menu .ant-menu-item.ant-menu-item-selected {
    display: flex;
    align-items: center;
    background: var(--white);
    color: var(--black);
}

.MobileSidebar .MobileSidebar-menu .ant-menu-item.ant-menu-item-selected::after {
    border: none;
}

.MobileSidebar .MobileSidebar-submenu {
    width: 100%;
}

.MobileSidebar .MobileSidebar-submenu .MobileSidebar-add-flight {
    display: flex;
    height: 36px;
    width: 100%;
    padding: 11px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 18px;
    border: 1px solid rgba(255, 255, 255, 0.24);
    background: none;
    color: white;
    font-weight: normal;
    margin-bottom: 10px;
}

.MobileSidebar .MobileSidebar-submenu .MobileSidebar-add-flight .ant-btn-icon {
    margin-inline-end: 0px;
}