.headerSessionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  align-self: center;
}

.icaoExpandView {
  white-space: normal;
}