.Past {
}

.Past .Past-container {
  max-width: 1150px;
  margin: 0 auto;
}

.mobile .Past .Past-container {
  padding-bottom: 135px;
}

.Past .Past-header {
  padding: 10px 16px;
}
