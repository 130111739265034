.Tags-container {
    max-height: 500px;
    overflow-y: auto;
}

.Tags-container .Tags-radio-group {
    width: 100%;
    padding: 0px 10px;
}

.Tags-new-tag {
    padding: 0px 10px;
}

.Tags-container .Tags-radio-group .Tag,
.Tags-new-tag .Tag {
    display: flex;
    align-items: center;
    padding: 12px;
}

.Tags-container .Tags-radio-group .Tag.selected,
.Tags-new-tag .Tag.selected,
.Tags-container .Tag.edit {
    border-radius: 8px;
    background: rgba(54, 81, 254, 0.08);
}

.Tags-new-tag .Tag.selected {
    margin: 10px 0;
}

.Tags-container .Tags-radio-group .Tag .Tag-color {
    margin-right: 10px
}

.Tags-container .Tags-radio-group .Tag .Tag-name {
    font-size: var(--fs1);
}

.Tags-container .Tags-radio-group .Tag .Tag-radio {
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;

}

.Tags-container .Tags-radio-group .Tag .Tag-radio span:not(.ant-radio) {
    flex: 1
}