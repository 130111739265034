.AuthScreen {
  max-width: 512px;
  margin: 0 auto;
  padding: 0 15px;
  --margin-top: 20px;

  color: #000;
}

.AuthScreen h1 {
  color: var(--black);
}

.AuthScreen .AuthScreen-logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.AuthScreen .AuthScreen-logo {
  margin-top: 64px;
  width: 256px;
  height: 72px;
}

.AuthScreen .AuthScreen-back-button:hover,
.AuthScreen .AuthScreen-back-button:focus {
  cursor: pointer;
  border: 1px solid var(--white);
}
.AuthScreen .AuthScreen-section {
  margin: 26px 0 0 0;
}

.AuthScreen .AuthScreen-header {
  color: var(--white);
  line-height: 35px;
  font-family: "Manrope";
}

.AuthScreen .AuthScreen-headerSub {
  color: var(--white);
  line-height: 25px;
  font-family: "Manrope";
  margin-top: var(--margin-top);
}

.AuthScreen .AuthScreen-phone {
  margin: var(--margin-top) 0 20px 0;
  background-color: #444;
  border-radius: 16px;
}

.AuthScreen .AuthScreen-timer {
  text-align: center;
  color: var(--black);
  font-size: 16px;
  font-family: "Manrope";
  padding: 0 20px;
  margin: 10px 0;
}

.AuthScreen .Button {
  margin: 10px 0;
}

.AuthScreen .Checkbox-label {
  color:  var(--black);
}

.AuthScreen a {
  color: var(--black);
}

.AuthScreen
  .react-international-phone-input-container
  .react-international-phone-country-selector-button {
  height: 48px;
  padding: 10px 0px 10px 10px;
  border-radius: var(--react-international-phone-border-radius, 16px);
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  background: rgba(0, 0, 0, 0.16);
  margin-right: 0px;
  border: 0px;
}

.AuthScreen
  .react-international-phone-input-container
  .react-international-phone-country-selector-dropdown {
  top: var(--react-international-phone-dropdown-left, 55px);
  border-radius: 8px;
  padding: 5px;
}

.AuthScreen
  .react-international-phone-input-container
  .react-international-phone-input {
  height: 48px;
  font-size: 16px;
  color: #fff;
  border-radius: var(--react-international-phone-border-radius, 16px);
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  background: rgba(0, 0, 0, 0.16);
  border: 0px;
}
.safari .AuthScreen-title-phone-number {
  font-weight: 599;
}
.safari .AuthScreen-title-otp-code {
  font-weight: 599;
}
@media only screen and (max-width: 440px) {
  .AuthScreen .AuthScreen-title-phone-number {
    font-size: x-large;
  }
  .AuthScreen .AuthScreen-section {
    margin: 26px 0 0 10px;
  }
  .AuthScreen .AuthScreen-title-otp-code {
    font-size: x-large;
  }
}
