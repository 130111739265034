.AddFlight {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  width: 100%;
  /* margin: 20px; */
}

.AddFlight .AddFlight-header {
  display: flex;
  align-items: center;
  color: '#444';
  font-family: "Disket-Mono-Regular";
  font-size: var(--fs5);
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -2px;
  padding: 20px 20px 0 20px;
}

.AddFlight .AddFlight-container {
  display: flex;
  flex-flow: row nowrap;
  overflow-y: auto;
  align-items: flex-start;
  justify-content: center;
}

.AddFlight .AddFlight-container .AddFlight-content {
  max-width: 1020px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 20px;
}

.AddFlight .AddFlight-container .AddFlight-content .AddFlight-scanner {
  width: 50%;
  margin-right: 10px;
}

.AddFlight .AddFlight-container .AddFlight-content .AddFlight-scanner .AddFlight-scan-container {
  background: #FFF;
  padding: 35px;
  border-radius: 16px;
  cursor: pointer;
}

.AddFlight .AddFlight-container .AddFlight-content .AddFlight-scanner .AddFlight-scan-container .AddFlight-scan-qr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AddFlight .AddFlight-container .AddFlight-content .AddFlight-scanner .AddFlight-scan-container .AddFlight-scan-qr img {
  width: 100%;
  /* max-width: 280px;
  max-height: 280px; */
}

.AddFlight .AddFlight-container .AddFlight-content .AddFlight-scanner .AddFlight-scan-container .AddFlight-scan-qr span {
  color: #3651FE;
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 10px;
}

.AddFlight .AddFlight-container .AddFlight-content .AddFlight-data {
  width: 50%;
  margin-left: 10px;
}

.scanText {
  text-align: center;
  margin-left: 40%;
}

.mobile .AddFlight .AddFlight-header {
  padding: 0px;
}

.mobile .AddFlight-container .AddFlight-content {
  margin: 0 20px 20px 20px;
}

.dateInputContainer .dateInput::-webkit-datetime-edit-day-field:focus,
.dateInputContainer .dateInput::-webkit-datetime-edit-month-field:focus,
.dateInputContainer .dateInput::-webkit-datetime-edit-year-field:focus {
    background-color: #444;
    color: white;
    outline: none;
}
@media screen and (max-width: 767px) {
  .AddFlight .AddFlight-container .AddFlight-content {
    flex-flow: column nowrap;
  }

  .AddFlight .AddFlight-container .AddFlight-content .AddFlight-scanner {
    width: 100%;
    margin-right: 0px;
  }

  .AddFlight .AddFlight-container .AddFlight-content .AddFlight-data {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px
  }
}