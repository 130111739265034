.OTPField label {
    text-align: center;
    width: 100%;
    margin: 0;
}

.OTPField .OTPField-inputs {
    font-size: 20px;
    display: flex;
    justify-content: center;

    margin: 10px 0;
}

.OTPField .OTPField-input {
  font-family: 'Manrope';
  width: 42px !important;
  height: 64px;
  line-height: 60px;
  font-size: var(--fs4);
  text-transform: uppercase;
  text-align: center;
  color: var(--white);
  border: 1px solid  --var(background);
  border-radius: 8px;
  background-color:  --var(background);
  overflow: hidden;
  user-select: none;
  margin: 0 3px;
}

.OTPField .OTPField-input::-webkit-outer-spin-button,
.OTPField .OTPField-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.OTPField .OTPField-input[type=number] {
  -moz-appearance: textfield;
}

.OTPField .OTPField-error {
    margin: 0;
    text-align: center;
}